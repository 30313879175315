/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */


import React, { Fragment, useState, useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";

let backendService;

const AboutUsForm = () => {
  const history = useNavigate();

  const [aboutUsData, setAboutUsData] = useState({
    title: '',
    content: '',
    signatory: '',
    whyChooseUs: [{ title: '', content: '' }],
  });

  useEffect(() => {
    
    const fetchAboutUsData = async () => {
      try {
        let env = process.env.NODE_ENV;
        if (env === 'development') {
          backendService = process.env.REACT_APP_Backend_Url_Dev;
        }
        else {
          backendService = process.env.REACT_APP_Backend_Url_Prod;
        }
        const response = await axios.get(`${backendService}/aboutus`);
        if (response.data && response.data.aboutUs) {
          setAboutUsData(response.data.aboutUs);
        }
      } catch (error) {
        console.error('Error fetching About Us data:', error);
      }
    };

    fetchAboutUsData();
  }, []);

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    const updatedWhyChooseUs = [...aboutUsData.whyChooseUs];
    updatedWhyChooseUs[index][field] = value;
    setAboutUsData({ ...aboutUsData, whyChooseUs: updatedWhyChooseUs });
  };

  const handleTitleOrSignatoryChange = (e) => {
    const { name, value } = e.target;
    setAboutUsData({ ...aboutUsData, [name]: value });
  };

  const handleContentChange = (e) => {
    setAboutUsData({ ...aboutUsData, content: e.target.value });
  };

  const addWhyChooseUsItem = () => {
    setAboutUsData({
      ...aboutUsData,
      whyChooseUs: [...aboutUsData.whyChooseUs, { title: '', content: '' }],
    });
  };

  const removeWhyChooseUsItem = (index) => {
    const updatedWhyChooseUs = [...aboutUsData.whyChooseUs];
    updatedWhyChooseUs.splice(index, 1);
    setAboutUsData({ ...aboutUsData, whyChooseUs: updatedWhyChooseUs });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${backendService}/aboutus`, aboutUsData);
      if (response.status === 200) {
        alert('About Us content updated successfully!');
        history('/aboutUs')
      } else {
        alert(`Failed to update About Us content. Server responded with status ${response.status}`);
      }
    } catch (error) {
      console.error('Error updating About Us content:', error);
      if (error.response) {
        // Server responded with an error
        alert(`Failed to update About Us content. Server responded with status ${error.response.status}`);
      } else if (error.request) {
        // No response was received from the server
        alert('Failed to update About Us content. Check your network connection.');
      } else {
        // Something else went wrong
        alert('An unexpected error occurred while updating About Us content.');
      }
    }
  };
  
    return (
    <Fragment>
        <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
      <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li class="nav-item dropdown">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div class="message-body">
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a>
                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!--  Header End --> */}
      <div class="container-fluid">
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                    <div className="container">
      <h2>Edit About Us</h2>
      <Form onSubmit={handleSubmit}>
          <Form.Group controlId="title">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={aboutUsData.title}
              onChange={handleTitleOrSignatoryChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="aboutUsContent">
            <Form.Label>About Us Content:</Form.Label>
            <Form.Control
              as="textarea"
              name="content"
              rows={6}
              value={aboutUsData.content}
              onChange={handleContentChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="signatory">
            <Form.Label>Signatory:</Form.Label>
            <Form.Control
              type="text"
              name="signatory"
              value={aboutUsData.signatory}
              onChange={handleTitleOrSignatoryChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="whyChooseUs">
            <Form.Label>
              <h3>Why Choose Us:</h3>
            </Form.Label>
            {aboutUsData.whyChooseUs.map((item, index) => (
              <div key={index}>
                <Form.Group controlId={`whyChooseUsTitle${index}`}>
                  <Form.Label>Title:</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.title}
                    onChange={(e) => handleInputChange(e, index, 'title')}
                    required
                  />
                </Form.Group>
                <Form.Group controlId={`whyChooseUsContent${index}`}>
                  <Form.Label>Content:</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={item.content}
                    onChange={(e) => handleInputChange(e, index, 'content')}
                    required
                  />
                </Form.Group>
                <Button variant="danger" onClick={() => removeWhyChooseUsItem(index)}>
                  Remove
                </Button>
              </div>
            ))}
            <Button variant="primary" onClick={addWhyChooseUsItem}>
              Add Why Choose Us
            </Button>
          </Form.Group>
          <Button type="submit" className="btn btn-primary">
            Update About Us
          </Button>
        </Form>
    </div>
               </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
    </Fragment>
    )
}

export default AboutUsForm;