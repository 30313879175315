/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */


import React, { Fragment, useState, useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./sidebar";

let backendService;
let env = process.env.NODE_ENV;
const ContactForm = () => {
  const [contactData, setContactData] = useState({
    phoneNumber: '',
    email: '',
    offices: [{ addressLine1: '', addressLine2: '', city: '', state: '', postCode: '' }],
    workHours: '',
  });
  if (env === 'development') {
    backendService = process.env.REACT_APP_Backend_Url_Dev;
  }
  else {
    backendService = process.env.REACT_APP_Backend_Url_Prod;
  }
  useEffect(() => {
    const fetchContactData = async () => {
      let env = process.env.NODE_ENV;
      if (env === 'development') {
        backendService = process.env.REACT_APP_Backend_Url_Dev;
      }
      else {
        backendService = process.env.REACT_APP_Backend_Url_Prod;
      }
      try {
        const response = await axios.get(`${backendService}/contact`);
        if (response.data && response.data.contact) {
          setContactData(response.data.contact);
        }
      } catch (error) {
        console.error('Error fetching contact data:', error);
      }
    };

    fetchContactData();
  }, []);

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    const updatedOffices = [...contactData.offices];
    updatedOffices[index][field] = value;
    setContactData({ ...contactData, offices: updatedOffices });
  };

  const handlePhoneNumberChange = (e) => {
    setContactData({ ...contactData, phoneNumber: e.target.value });
  };

  const handleEmailChange = (e) => {
    setContactData({ ...contactData, email: e.target.value });
  };

  const handleWorkHoursChange = (e) => {
    setContactData({ ...contactData, workHours: e.target.value });
  };

  const addOfficeItem = () => {
    setContactData({
      ...contactData,
      offices: [...contactData.offices, { addressLine1: '', addressLine2: '', city: '', state: '', postCode: '' }],
    });
  };

  const removeOfficeItem = (index) => {
    const updatedOffices = [...contactData.offices];
    updatedOffices.splice(index, 1);
    setContactData({ ...contactData, offices: updatedOffices });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${backendService}/contact`, contactData);
      if (response.status === 200) {
        alert('Contact information updated successfully!');
      } else {
        alert(`Failed to update contact information. Server responded with status ${response.status}`);
      }
    } catch (error) {
      console.error('Error updating contact information:', error);
      if (error.response) {
        // Server responded with an error
        alert(`Failed to update contact information. Server responded with status ${error.response.status}`);
      } else if (error.request) {
        // No response was received from the server
        alert('Failed to update contact information. Check your network connection.');
      } else {
        // Something else went wrong
        alert('An unexpected error occurred while updating contact information.');
      }
    }
  };
  
    return (
    <Fragment>
        <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
    <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li class="nav-item dropdown">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div class="message-body">
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a>
                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!--  Header End --> */}
      <div class="container-fluid">
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                    <div className="container">
                    <div>
                    <h2>Contact Information</h2>
                    <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control
                      type="text"
                      value={contactData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      value={contactData.email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                  <Form.Label>Work Hours:</Form.Label>
                  <Form.Control
                    type="text"
                    value={contactData.workHours}
                    onChange={handleWorkHoursChange}
                    required
                  />
                </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <h3>Offices:</h3>
                    </Form.Label>
                    {contactData.offices.map((office, index) => (
                      <div key={index}>
                        <Form.Group>
                          <Form.Label>Address Line 1:</Form.Label>
                          <Form.Control
                            type="text"
                            value={office.addressLine1}
                            onChange={(e) => handleInputChange(e, index, "addressLine1")}
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Address Line 2:</Form.Label>
                          <Form.Control
                            type="text"
                            value={office.addressLine2}
                            onChange={(e) => handleInputChange(e, index, "addressLine2")}
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>City:</Form.Label>
                          <Form.Control
                            type="text"
                            value={office.city}
                            onChange={(e) => handleInputChange(e, index, "city")}
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>State:</Form.Label>
                          <Form.Control
                            type="text"
                            value={office.state}
                            onChange={(e) => handleInputChange(e, index, "state")}
                            required
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Post Code:</Form.Label>
                          <Form.Control
                            type="text"
                            value={office.postCode}
                            onChange={(e) => handleInputChange(e, index, "postCode")}
                            required
                          />
                        </Form.Group>
                        <Button variant="danger" onClick={() => removeOfficeItem(index)}>
                          Remove Office
                        </Button>
                      </div>
                    ))}
                    <Button variant="primary" onClick={addOfficeItem}>
                      Add Office
                    </Button>
                  </Form.Group>
                  <Button type="submit" className="btn btn-primary">
                    Update Contact Information
                  </Button>
                </Form>
                  </div>
                  </div>
               </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
    </Fragment>
    )
}

export default ContactForm;