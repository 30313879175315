/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Sidebar from "./sidebar";
let backendService;
let env = process.env.NODE_ENV;
if (env === 'development') {
    backendService = process.env.REACT_APP_Backend_Url_Dev;
  }
  else {
    backendService = process.env.REACT_APP_Backend_Url_Prod;
  }
function UpdateProject() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [image, setImage] = useState(null);

    const location = useLocation();
    const projectId = new URLSearchParams(location.search).get('id');
    const history = useNavigate();

    useEffect(() => {
        // Fetch the testimonial data using the testimonialId
        async function fetchService() {
            try {
                const response = await axios.get(`${backendService}/projects/${projectId}`);
                const serviceData = response.data;
                setTitle(serviceData.title);
                setContent(serviceData.content)
                setSelectedCategory(serviceData.category);
            } catch (error) {
                console.log(error);
            }
        }

        fetchService();
    }, [projectId]);
    function handleDisableClick() {
        const projectId = new URLSearchParams(location.search).get('id'); // Replace with the actual article id

        // Make the PUT request to the /article/:id/disable endpoint

        // Retrieve the updated article

        axios.get(`${backendService}/projects/${projectId}`)
            .then(response => {
                const project = response.data;
                const confirmation = window.confirm("Are you sure you want to disable this project?");

                if (confirmation) {
                    axios.put(`${backendService}/project/${projectId}/disable`)
                        .then(() => {
                            // Check if IsActive is false
                            if (project.IsActive === false) {
                                // Do nothing and display a message to the user
                                alert("This project is already disabled!");
                            }
                        })

                        .catch(error => {
                            console.log(error);
                        });
                    history('/projects');

                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    function handleEnableClick() {
        const projectId = new URLSearchParams(location.search).get('id'); // Replace with the actual category id

        // Make the PUT request to the /category/:id/disable endpoint
        axios.get(`${backendService}/projects/${projectId}`)
            .then(response => {
                const project = response.data;
                axios.put(`${backendService}/project/${projectId}/enable`)
                    .then(() => {
                        // Check if IsActive is false
                        if (project.IsActive === true) {
                            // Do nothing and display a message to the user
                            alert("This project is already enabled!");
                        } else {
                            // Navigate to another page
                            history('/projects');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        // Fetch the category data from the API
        axios.get(`${backendService}/categoryTitle`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    async function Submit(e) {
        e.preventDefault();

        // Create an object with the updated testimonial data
      

        try {
            
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('category', selectedCategory);
            formData.append('image', image);
            // Make a PUT request to update the testimonial
            const response = await axios.put(`${backendService}/updateProject/${projectId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        
            const { message } = response.data;
        
            if (response.status === 200) {
                alert(message); // Display a success message
                history("/projects");
            } else {
                alert(message); // Display the message received from the backend
            } 
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                alert(error.response.data.message); // Display the error message from the server
            } else if (error.request) {
                // The request was made but no response was received
                alert('No response received from the server');
            } else {
                // Something happened in setting up the request that triggered an error
                alert('Error setting up the request');
            }
        }
    }

    return (
        <Fragment>
            <body>

                {/* <!--  Body Wrapper --> */}
                <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
                    data-sidebar-position="fixed" data-header-position="fixed">
                    {/* <!-- Sidebar Start --> */}
                    <Sidebar />
                    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
                    <div class="body-wrapper">
                        {/* <!--  Header Start --> */}
                        <header class="app-header">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <ul class="navbar-nav">
                                    <li class="nav-item d-block d-xl-none">
                                        <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                                            <i class="ti ti-menu-2"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                                            <i class="ti ti-bell-ringing"></i>
                                            <div class="notification bg-primary rounded-circle"></div>
                                        </a>
                                    </li>
                                </ul>
                                <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                                    <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle" />
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                                <div class="message-body">
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-user fs-6"></i>
                                                        <p class="mb-0 fs-3">My Profile</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-mail fs-6"></i>
                                                        <p class="mb-0 fs-3">My Account</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-list-check fs-6"></i>
                                                        <p class="mb-0 fs-3">My Task</p>
                                                    </a>
                                                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </header>
                        {/* <!--  Header End --> */}
                        <div class="container-fluid">
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <Form onSubmit={Submit}>
                                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-between">
                                                <div>
                                                    <h1 className="px-0">Edit Project</h1>
                                                </div>
                                                <div className="mb-5">
                                                    <Button variant="primary" type="submit" className="btn btn-primary">Save</Button>
                                                </div>
                                            </ul>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" required placeholder="Title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control type="text" required placeholder="Content" value={content} onChange={(e) => { setContent(e.target.value) }} />
                                            </Form.Group>
                                           
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="image">
                                                <Form.Label>Upload Image:</Form.Label>
                                                <Form.Control
                                                    onChange={(e) => setImage(e.target.files[0])}
                                                    type="file"
                                                    name="image"
                                                />                                              </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                                                <Form.Label>Select Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedCategory}
                                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                                >
                                                    <option value="">Choose...</option>
                                                    {categories.map(category => (
                                                        <option key={category._id} value={category.Title}>{category.Title}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Form>

                                        <span className="m-2">
                                            <button className="btn btn-primary" onClick={handleEnableClick}>
                                                Enable
                                            </button>
                                        </span>
                                        <span>
                                            <button className="btn btn-primary" onClick={handleDisableClick}>
                                                Disable
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
                <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
                <script src="../assets/js/sidebarmenu.js"></script>
                <script src="../assets/js/app.min.js"></script>
                <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
            </body>
        </Fragment>
    )
}

export default UpdateProject;