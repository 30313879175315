/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import Sidebar from "./sidebar";

let backendService;
let env = process.env.NODE_ENV;
const ConsultationInfoPage = () => {
  const [consultations, setConsultations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  if (env === 'development') {
    backendService = process.env.REACT_APP_Backend_Url_Dev;
  }
  else {
    backendService = process.env.REACT_APP_Backend_Url_Prod;
  }
  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        let env = process.env.NODE_ENV;
        if (env === 'development') {
          backendService = process.env.REACT_APP_Backend_Url_Dev;
        }
        else {
          backendService = process.env.REACT_APP_Backend_Url_Prod;
        }
        const response = await axios.get(`${backendService}/consultations/list`);
        const sortedConsultations = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setConsultations(sortedConsultations);
      } catch (error) {
        console.error('Error fetching consultations:', error);
      }
    };

    fetchConsultations();
  }, []); // Run once on component mount

  const handleOpenModal = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  return (
        <Fragment>
            <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
    <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li class="nav-item dropdown">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div class="message-body">
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a>
                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!--  Header End --> */}
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title fw-semibold mb-4 mt-9">Consultation Information</h5>
            <div class="table-responsive">
            <table class="table text-nowrap mb-0 align-middle">
              <thead class="text-dark fs-4">
              <tr>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Name</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Phone Number</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Email</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Topic</h6>
                  </th>
                  <th class="border-bottom-0">
                    <h6 class="fw-semibold mb-0">Message</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                  {consultations.map((consultation, index) => (
                    <tr key={index}>
                      <td>{consultation.name}</td>
                      <td>{consultation.phoneNumber}</td>
                      <td>{consultation.email}</td>
                      <td>{consultation.topic}</td>
                      <Button variant="primary" onClick={() => handleOpenModal(consultation.message)}>
                          Read Message
                        </Button>
                    </tr>
                  ))}
                </tbody>
      </table>
    </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Message</Modal.Title>
  </Modal.Header>
  <Modal.Body>{selectedMessage}</Modal.Body>
  <Modal.Footer>
  <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
  </Modal.Footer>
</Modal>
    </div>
  </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
        </Fragment>
    )
}

export default ConsultationInfoPage;