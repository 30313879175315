/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import Sidebar from "./sidebar";

const history = useNavigate;

let backendService;
function Projects() {

    const [project, setProject] = useState([])
    useEffect(() => {
        let env = process.env.NODE_ENV;
        if (env === 'development') {
            backendService = process.env.REACT_APP_Backend_Url_Dev;
        }
        else {
            backendService = process.env.REACT_APP_Backend_Url_Prod;
        }
        axios.get(`${backendService}/projects`)
            .then(project => setProject(project.data))
            .then(console.log(project))
            .catch(err => console.log(err))
    }, [])

    const Delete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this project?");

        if (confirmed) {
            try {
                let res = await fetch(`${backendService}/deleteProject/${id}`, {
                    method: "DELETE",
                    headers: { 'content-type': "application/json" }
                });
                let response = await res.json();

                if (res.status === 200) {
                    alert(response.message);
                    history(window.location.href = '/projects');
                } else {
                    alert(response.message);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            alert("Deletion canceled");
        }
    }

    const getImageUrl = (imageName) => {
        return `${backendService}/uploads/${imageName}`; // Adjust the path as per your server setup
    };

    return (
        <Fragment>
            <body>
                {/* <!--  Body Wrapper --> */}
                <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
                    data-sidebar-position="fixed" data-header-position="fixed">
                    {/* <!-- Sidebar Start --> */}
                    <Sidebar />
                    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
                    <div class="body-wrapper">
                        {/* <!--  Header Start --> */}
                        <header class="app-header">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <ul class="navbar-nav">
                                    <li class="nav-item d-block d-xl-none">
                                        <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                                            <i class="ti ti-menu-2"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                                            <i class="ti ti-bell-ringing"></i>
                                            <div class="notification bg-primary rounded-circle"></div>
                                        </a>
                                    </li>
                                </ul>
                                <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                                    <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle" />
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                                <div class="message-body">
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-user fs-6"></i>
                                                        <p class="mb-0 fs-3">My Profile</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-mail fs-6"></i>
                                                        <p class="mb-0 fs-3">My Account</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-list-check fs-6"></i>
                                                        <p class="mb-0 fs-3">My Task</p>
                                                    </a>
                                                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                            <div class="navbar-collapse justify-content-end px-0 mb-9" id="navbarNav">
                                <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                    <Link to="/addProjects" class="btn btn-primary">Add New</Link>
                                </ul>
                            </div>
                        </header>
                        {/* <!--  Header End --> */}
                        <div class="container-fluid">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title fw-semibold mb-4 mt-9">Projects</h5>


                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Title
                                                    </th>
                                                    <th>
                                                        Category
                                                    </th>
                                                    <th>
                                                        Content
                                                    </th>
                                                    <th>
                                                        Image
                                                    </th>
                                                    <th>
                                                        Created Date
                                                    </th>
                                                    <th>
                                                        Last Updated Date
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    project.map(project => {
                                                        return <tr>
                                                            <td>{project.title}</td>
                                                            <td>{project.category}</td>
                                                            <td>{project.content}</td>

                                                            <td>
                                                                <img src={getImageUrl(project.image)} alt="Team Member" className="img-fluid" style={{ maxWidth: "100px" }} />
                                                            </td>
                                                            <td>{new Date(project.CreatedDate).toISOString().split('T')[0]}</td>
                                                            <td>{project.LastUpdatedDate ? new Date(project.LastUpdatedDate).toISOString().split('T')[0] : ''}</td>
                                                            <td>
                                                                <div>
                                                                    <Badge className={project.IsActive ? 'bg-success' : 'bg-danger'}>
                                                                        {project.IsActive ? "Active" : "Inactive"}
                                                                    </Badge>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <Link to={`/updateProject?id=${project._id}`} class="btn btn-primary">Edit</Link>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <Link class="btn btn-primary" onClick={() => Delete(project._id)}>Delete</Link>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
                <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
                <script src="../assets/js/sidebarmenu.js"></script>
                <script src="../assets/js/app.min.js"></script>
                <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
            </body>
        </Fragment>
    )
}

export default Projects;