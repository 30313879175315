import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Sidebar() {
    return(<Fragment>
         <aside class="left-sidebar">
    {/* <!-- Sidebar scroll--> */}
    <div>
      <div class="brand-logo d-flex align-items-center justify-content-between">
        <a href="./index.html" class="text-nowrap logo-img">
          <img src="assets/images/logos/dark-logo.svg" width="180" alt="" />
        </a>
        <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
          <i class="ti ti-x fs-8"></i>
        </div>
      </div>
      {/* <!-- Sidebar navigation--> */}
      <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
        <ul id="sidebarnav">
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">Home</span>
          </li>
          <li class="sidebar-item">
            <Link to="/index" onClick={() => { window.location.href = "/index" }}><a class="sidebar-link" href="/index.html" aria-expanded="false">
              <span>
                <i class="ti ti-layout-dashboard"></i>
              </span>
              <span class="hide-menu">Dashboard</span>
            </a></Link>
          </li>
          <li class="nav-small-cap">
            <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
            <span class="hide-menu">PAGES</span>
          </li>
          <li class="sidebar-item">
            <Link to="/sliders"><a class="sidebar-link" href="./sample-page.html" aria-expanded="false">
              <span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Sliders</span>
            </a>
            </Link>
          </li>
          <li class="sidebar-item">
            <Link to="/social"><a class="sidebar-link" href="./sample-page.html" aria-expanded="false">
              <span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Social Media</span>
            </a>
            </Link>
          </li>
          <li class="sidebar-item">
            <Link to="/service"><a class="sidebar-link" href="./sample-page.html" aria-expanded="false">
              <span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Services</span>
            </a></Link>
          </li>
          <li class="sidebar-item">
            <Link to="/projects"><a class="sidebar-link" href="./sample-page.html" aria-expanded="false">
              <span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Projects</span>
            </a></Link>
          </li>
          <li class="sidebar-item m-2">
            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="aboutDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span>
                  <i class="ti ti-aperture fs-6 "></i>
                </span>
                <span class="hide-menu m-3">About</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                <li class="dropdown-item">
                  <Link to="/teams" className="sidebar-link" aria-expanded="false">
                    <span>
                      <i class="ti ti-aperture"></i>
                    </span>
                    <span className="hide-menu">Our Teams</span>
                  </Link>
                </li>
                <li class="dropdown-item">
                  <Link to="/overview" className="sidebar-link" aria-expanded="false">
                    <span>
                      <i class="ti ti-aperture"></i>
                    </span>
                    <span className="hide-menu">Overview</span>
                  </Link>
                </li>
                <li class="dropdown-item">
                  <Link to="/aboutUs" className="sidebar-link" aria-expanded="false">
                    <span>
                      <i class="ti ti-aperture"></i>
                    </span>
                    <span className="hide-menu">AboutUs</span>
                  </Link>
                </li>
                <li class="dropdown-item">
                  <Link to="/testimonial" className="sidebar-link" aria-expanded="false">
                    <span>
                      <i class="ti ti-aperture"></i>
                    </span>
                    <span className="hide-menu">Testimonial</span>
                  </Link>
                </li>
                <li class="dropdown-item">
                  <Link to="/contact" className="sidebar-link" aria-expanded="false">
                    <span>
                      <i class="ti ti-aperture"></i>
                    </span>
                    <span className="hide-menu">Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>


          <li class="sidebar-item m-2">
            <div class="dropdown">
              <a class="dropdown-toggle" href="#" role="button" id="aboutDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <span>
                  <i class="ti ti-aperture fs-6 "></i>
                </span>
                <span class="hide-menu m-3">Aticle</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                <li class="dropdown-item">
                  <Link to="/category" className="sidebar-link" aria-expanded="false">
                  <span>
                    <i class="ti ti-aperture"></i>
                  </span>
                  <span className="hide-menu">Category</span>
                </Link>
                </li>
                <li class="dropdown-item">
                    <Link to="/articles" className="sidebar-link" aria-expanded="false">
                  <span>
                    <i class="ti ti-aperture"></i>
                  </span>
                  <span className="hide-menu">Articles</span>
                </Link>
                </li>
              </ul>
            </div>
          </li>



          <li class="sidebar-item">
            <Link to="/consultationInfoPage"><a class="sidebar-link" href="./sample-page.html" aria-expanded="false">
              <span>
                <i class="ti ti-aperture"></i>
              </span>
              <span class="hide-menu">Consultation</span>
            </a></Link>
          </li>
        </ul>
        <div class="unlimited-access hide-menu bg-light-primary position-relative mb-7 mt-5 rounded">
          <div class="d-flex">
            <div class="unlimited-access-title me-3">
              <h6 class="fw-semibold fs-4 mb-6 text-dark w-85">Upgrade to pro</h6>
              <a href="https://adminmart.com/product/modernize-bootstrap-5-admin-template/" target="_blank" class="btn btn-primary fs-2 fw-semibold lh-sm" rel="noreferrer">Buy Pro</a>
            </div>
            <div class="unlimited-access-img">
              <img src="assets/images/backgrounds/rocket.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- End Sidebar navigation --> */}
    </div>
    {/* <!-- End Sidebar scroll--> */}
  </aside>
    </Fragment>)
   
}

export default Sidebar;