/* eslint-disable no-script-url */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  import React, { Fragment, useState, useEffect, useRef } from "react";
  import { Link, useParams, useNavigate } from "react-router-dom";
  import { Form, Button } from "react-bootstrap";
  import axios from "axios";
  import * as Yup from "yup";
import Sidebar from "./sidebar";

  let backendService;
  
  function EditTeam() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [teamMember, setTeamMember] = useState({});
    const [formData, setFormData] = useState({});
    const [image, setImage] = useState(null);
    const imageInputRef = useRef(null);
  
  useEffect(() => {
      const fetchTeamMember = async () => {
        let env = process.env.NODE_ENV;
        if (env === 'development') {
          backendService = process.env.REACT_APP_Backend_Url_Dev;
        }
        else {
          backendService = process.env.REACT_APP_Backend_Url_Prod;
        }
        try {
          console.log('Fetching team member with ID:', id); // Log the ID
          const response = await axios.get(`${backendService}/editTeam/${id}`);
          console.log('Response:', response.data); // Log the response data
          setTeamMember(response.data);
          setFormData(response.data);
        } catch (error) {
          console.error("Error fetching team member:", error);
        }
      };
      fetchTeamMember();
    }, [id]);
    
  
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
      setFormData({ ...formData, image: e.target.files[0] });
    };
  
    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      status: Yup.string().required("Status is required"),
      profile: Yup.string().required("Profile is required"),
      designation: Yup.string().required("Designation is required"),
    });
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        // Validate the form data
        await validationSchema.validate(formData, { abortEarly: false });
  
        // Create a new FormData object
        const updatedFormData = new FormData();
        Object.keys(formData).forEach((key) => {
          updatedFormData.append(key, formData[key]);
        });
  
        // Append the image file
        if (image) {
          updatedFormData.append("image", image);
        }
        // Update the team member
        await axios.put(`${backendService}/team/${id}`, updatedFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Team member profile updated successfully");
        navigate("/teams");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          // Display validation errors
          error.inner.forEach((err) => {
            alert(`${err.path}: ${err.message}`);
          });
        } else {
          console.error("Error updating team member profile:", error);
        }
      }
    };
  
      return(
          <Fragment>
              <body>
    {/* <!--  Body Wrapper --> */}
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      {/* <!-- Sidebar Start --> */}
      <Sidebar />
      {/* <!--  Sidebar End -->
      <!--  Main wrapper --> */}
      <div class="body-wrapper">
        {/* <!--  Header Start --> */}
        <header class="app-header">
          <nav class="navbar navbar-expand-lg navbar-light">
            <ul class="navbar-nav">
              <li class="nav-item d-block d-xl-none">
                <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                  <i class="ti ti-menu-2"></i>
                </a>
              </li>
             
            </ul>
            <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
              <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              </ul>
            </div>
          </nav>
        </header>
        {/* <!--  Header End --> */}
        <div className="container-fluid">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">EDIT TEAM</h5>
        <div className="table-responsive">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-9">
                <h2>Editing: {teamMember.firstName} {teamMember.lastName}</h2>
                <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            placeholder="First Name"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            placeholder="Last Name"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      placeholder="Status"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="profile"
                      value={formData.profile}
                      onChange={handleInputChange}
                      placeholder="Profile"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="designation"
                      value={formData.designation}
                      onChange={handleInputChange}
                      placeholder="Designation"
                    />
                  </Form.Group>
                        {/* Other form fields go here */}
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="file"
                            name="image"
                            ref={imageInputRef}
                            onChange={handleImageChange}
                          />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="btn btn-primary">Update Profile</Button>
                      </Form>
                  
                  
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
            </div>
          </div>
  
    <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
    <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
    <script src="../assets/js/sidebarmenu.js"></script>
    <script src="../assets/js/app.min.js"></script>
    <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
  </body>
          </Fragment>
      )
  }
  export default EditTeam;