// eslint-disable-next-line no-unused-vars
import logo from './logo.svg';
import './App.css';
import Login from './Pages/login';
import Register from './Pages/register';
import Index from './Pages';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Service from './Pages/service';
import AddService from './Pages/addService';
import Article from './Pages/article';
import NewArticle from './Pages/addArticle';
import Teams from './Pages/teams';
import NewTeam from './Pages/new-team';
import Overview from './Pages/overview.js';
import AddOverview from './Pages/addOverview.js';
import UpdateOverview from './Pages/updateOverview.js';
import EditTeam from './Pages/editTeam';
import AboutUs from './Pages/aboutUs';
import AboutUsForm from './Pages/aboutUsForm';
import ConsultationInfoPage from './Pages/ConsultationInfoPage';
import AddTestimonial from './Pages/addTestimonial';
import Testimonial from './Pages/testimonial';
import UpdateTestimonial from './Pages/updateTestimonial';
import UpdateService from './Pages/updateService';
import Contact from './Pages/contact';
import ContactForm from './Pages/contactForm';
import AddArticleCategory from './Pages/addAticleCategory';
import ArticleCategory from './Pages/articleCategory';
import Updatecategory from './Pages/updateCategory';
import Articles from './Pages/articles';
import UpdateArticles from './Pages/updateArticles';
import AddArticles from './Pages/addArticles';
import Projects from './Pages/projects';
import AddProjects from './Pages/addProjects.js';
import UpdateProject from './Pages/updateProject.js';
import Slider from './Pages/slider.js';
import AddSlider from './Pages/addSlider.js';
import UpdateSlider from './Pages/updateSlider.js';
import Social from './Pages/social.js';
import AddSocial from './Pages/addSocial.js';
import UpdateSocial from './Pages/updateSocial.js';

function App() {
  return (
    <div className="App">
     <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/index' element={<Index />} />
        <Route path='/service' element={<Service />} />
        <Route path='/addService' element={<AddService />} />
        <Route path='/article' element={<Article />} />
        <Route path='/addArticle' element={<NewArticle />} />
        <Route path='/teams' element={<Teams />} />
        <Route path='/overview' element={<Overview />} />
        <Route path='/addOverview' element={<AddOverview />} />
        <Route path='/updateOverview/' element={<UpdateOverview />} />
        <Route path='/new-team' element={<NewTeam />} />
        <Route path='/editTeam/:id' element={<EditTeam />} />
        <Route path='/aboutUs' element={<AboutUs />} />
        <Route path='/aboutUsForm' element={<AboutUsForm />} />
        <Route path='/ConsultationInfoPage' element={<ConsultationInfoPage />} />
        <Route path='/addTestimonial' element={<AddTestimonial />} />
        <Route path='/testimonial' element={<Testimonial />} />
        <Route path='/updateTestimonial/' element={<UpdateTestimonial />} />
        <Route path='/updateService/' element={<UpdateService />} />
        <Route path='/contact/' element={<Contact />} />
        <Route path='/contactForm/' element={<ContactForm />} />
        <Route path='/addArticleCategory' element={<AddArticleCategory />} />
        <Route path='/category' element={<ArticleCategory />} />
        <Route path='/updateCategory/' element={<Updatecategory />} />
        <Route path='/articles' element={<Articles />} />
        <Route path='/addArticles' element={<AddArticles />} />
        <Route path='/updateArticle/' element={<UpdateArticles />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/addProjects' element={<AddProjects />} />
        <Route path='/updateProject/' element={<UpdateProject />} />
        <Route path='/sliders' element={<Slider />} />
        <Route path='/addSlider' element={<AddSlider />} />
        <Route path='/updateSlider/' element={<UpdateSlider />} />
        <Route path='/social' element={<Social />} />
        <Route path='/addSocial' element={<AddSocial />} />
        <Route path='/updateSocial/' element={<UpdateSocial />} />







      </Routes>
     </Router>
    </div>
  );
}

export default App;
