  /* eslint-disable no-script-url */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  import React, { Fragment, useState, useEffect } from "react";
  import { Form, FloatingLabel, Col, Row, Button } from 'react-bootstrap';
  import { Link } from "react-router-dom";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";

  let backendService;
  
  function NewTeam({ setRefetchTeam }) {
    const history = useNavigate();
    const [currentDate, setCurrentDate] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [profile, setProfile] = useState('');
    const [image, setImage] = useState(null); // Use null for file state
    const [designation, setDesignation] = useState('');

  
    useEffect(() => {
      // Function to update the current date
      const updateCurrentDate = () => {
        const now = new Date();
        const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
        setCurrentDate(formattedDate);
      };
  
      // Update the date initially and then every 24 hours
      updateCurrentDate();
      const intervalId = setInterval(updateCurrentDate, 24 * 60 * 60 * 1000);
  
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        let env = process.env.NODE_ENV;
        if (env === 'development') {
          backendService = process.env.REACT_APP_Backend_Url_Dev;
        }
        else {
          backendService = process.env.REACT_APP_Backend_Url_Prod;
        }
        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('status', status);
        formData.append('profile', profile);
        formData.append('image', image); // Append image file
        formData.append('designation', designation);
  
        await axios.post(`${backendService}/new-team`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert("Team member added successfully");
        history('/teams');
      }catch (e) {
        console.log(e);
      }
    };




    return(
        <Fragment>
            <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
   <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
           
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <p class="btn btn-primary">Add New</p>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!--  Header End --> */}
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title fw-semibold mb-4">ADD NEW TEAM</h5>
            <div class="table-responsive">
            <div class="container mt-5">
  <div class="row">
    <div class="col-md-9">
      <h2>Add Team Member</h2>
      {/* <!-- <Form action="post" method="/new-team">--> */}
      <form onSubmit={handleSubmit}>
        <Row className="g-2 mb-3">
          <Col md>
            <FloatingLabel controlId="floatingInputGrid1" label="First name">
              <Form.Control onChange={(e) => setFirstName(e.target.value)} type="text" placeholder="First name" />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInputGrid2" label="Last name">
              <Form.Control onChange={(e) => setLastName(e.target.value)} type="text" placeholder="Last name" />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="g-2 mb-3">
          <Col md>
            <FloatingLabel controlId="floatingInputGrid3" label="Email address">
              <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email address" />
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingSelectGrid" label="Status">
              <Form.Select onChange={(e) => setStatus(e.target.value)} defaultValue="Select status">
                <option value="">Select status</option>
                <option value="Amateur">Amateur</option>
                <option value="Professional">Professional</option>
                <option value="Veteran">Veteran</option>
                <option value="Expert">Expert</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="profile">
          <Form.Label>Profile:</Form.Label>
          <Form.Control onChange={(e) => setProfile(e.target.value)} type="text" placeholder="Enter Profile" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="image">
        <Form.Label>Upload Image:</Form.Label>
        <Form.Control onChange={(e) => setImage(e.target.files[0])} type="file" name="image" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="designation">
          <Form.Label>Designation:</Form.Label>
          <Form.Control onChange={(e) => setDesignation(e.target.value)} type="text" placeholder="Enter Designation" />
        </Form.Group>
        <div className="mb-3">
                      <label htmlFor="currentDate" className="form-label">Current Date:</label>
                      <p className="form-control-plaintext" id="currentDate">{currentDate}</p>
                    </div>
        <div className="mb-3">
          <Button variant="primary" type="submit">
            Add Team Member
          </Button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
        </Fragment>
    )
}
export default NewTeam;