/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";

let backendService;
let env = process.env.NODE_ENV;
if (env === 'development') {
  backendService = process.env.REACT_APP_Backend_Url_Dev;
}
else {
  backendService = process.env.REACT_APP_Backend_Url_Prod;
}

function Teams() {
  const [team, setTeam] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const history = useNavigate();

  useEffect(() => {
    fetchTeam();
    updateCurrentDate(); // Initial update
    const intervalId = setInterval(updateCurrentDate, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);


  const fetchTeam = async () => {
    try {
      const response = await axios.get(`${backendService}/team`);
      setTeam(response.data);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };


  const updateCurrentDate = () => {
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
    setCurrentDate(formattedDate);
  };

  const getImageUrl = (imageName) => {
    return `${backendService}/uploads/${imageName}`; // Adjust the path as per your server setup
  };

  const Delete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this team member?");

    if (confirmed) {
      try {
        let res = await fetch(`${backendService}/deleteTeam/${id}`, {
          method: "DELETE",
          headers: { 'content-type': "application/json" }
        });
        let response = await res.json();

        if (res.status === 200) {
          alert(response.message);
          history(window.location.href = '/teams');
        } else {
          alert(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Deletion canceled");
    }
  }

    return(
        <Fragment>
            <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
    <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li class="nav-item dropdown">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div class="message-body">
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a>
                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div class="navbar-collapse justify-content-end px-0 mb-9" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <Link  to="/new-team" class="btn btn-primary">Add New</Link>
            </ul>
          </div>
      </header>
      {/* <!--  Header End --> */}
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title fw-semibold mb-4 mt-9">OUR-TEAMS</h5>
            <div class="table-responsive">
                  <table class="table text-nowrap mb-0 align-middle">
                    <thead class="text-dark fs-4">
                      <tr>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Id</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Name</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Email</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Status</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Date</h6>
                        </th>
                        <th class="border-bottom-0">
                          <h6 class="fw-semibold mb-0">Image</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        {team.map((member) => (
                          <tr key={member._id}>
                            <td class="border-bottom-0">
                              <h6 class="fw-semibold mb-0">{member._id}</h6>
                            </td>
                            <td class="border-bottom-0">
                              <h6 class="fw-semibold mb-1">{member.firstName} {member.lastName}</h6>
                            </td>
                            {/* <!--  <td class="border-bottom-0">
                              <h6 class="fw-semibold mb-1">{member.email}</h6>
                            </td> --> */}
                            <td class="border-bottom-0">
                              <a href={`mailto:${member.email}`} className="fw-semibold mb-1">{member.email}</a>
                            </td>

                            <td class="border-bottom-0">
                              <div class="flex align-items-center">
                                <span class={`badge bg-${getStatusColor(member.status)} rounded-3 fw-semibold mb-1`}>{member.status}</span>
                              </div>
                            </td>
                            <td class="border-bottom-0">
                              <p class="mb-0 fw-normal">{member.date}</p>
                              <div className="mb-3">
                              <label htmlFor="currentDate" className="form-label">Created Date</label>
                              <input type="text" className="form-control" id="currentDate" value={currentDate} readOnly />
                            </div>
                            </td>
                            <td class="border-bottom-0">
                            <img src={getImageUrl(member.image)} alt="Team Member" className="img-fluid" style={{ maxWidth: "100px" }} />
                            </td>
                            <td>
                            <Link to={`/editTeam/${member._id}`} className="btn btn-primary">Edit</Link>
                            </td>
                            <td>
                            <Link class="btn btn-primary" onClick={() => Delete(member._id)}>Delete</Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                  </table>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
        </Fragment>
    )
}
const getStatusColor = (status) => {
  switch (status) {
    case "Amateur":
      return "primary";
    case "Professional":
      return "secondary";
    case "Veteran":
      return "danger";
    case "Expert":
      return "success";
    default:
      return "primary";
  }
};

export default Teams;