/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */


import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./sidebar";

let backendService;
let env = process.env.NODE_ENV;
const Contact = () => {
    const [contactData, setContactData] = useState({
      phoneNumber: '',
      email: '',
      offices: [{ addressLine1: '', addressLine2: '', city: '', state: '', postCode: '' }],
      workHours: '',
    });
    if (env === 'development') {
      backendService = process.env.REACT_APP_Backend_Url_Dev;
    }
    else {
      backendService = process.env.REACT_APP_Backend_Url_Prod;
    }
    useEffect(() => {
      const fetchContactData = async () => {
        try {
          let env = process.env.NODE_ENV;
          if (env === 'development') {
            backendService = process.env.REACT_APP_Backend_Url_Dev;
          }
          else {
            backendService = process.env.REACT_APP_Backend_Url_Prod;
          }
          const response = await axios.get(`${backendService}/contact`);
          if (response.data && response.data.contact) {
            setContactData(response.data.contact);
          }
        } catch (error) {
          console.error('Error fetching contact data:', error);
        }
      };
  
      fetchContactData();
    }, []);
  
    return (
    <Fragment>
        <body>
  {/* <!--  Body Wrapper --> */}
  <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
    data-sidebar-position="fixed" data-header-position="fixed">
    {/* <!-- Sidebar Start --> */}
   <Sidebar />
    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
    <div class="body-wrapper">
      {/* <!--  Header Start --> */}
      <header class="app-header">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-block d-xl-none">
              <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                <i class="ti ti-menu-2"></i>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                <i class="ti ti-bell-ringing"></i>
                <div class="notification bg-primary rounded-circle"></div>
              </a>
            </li>
          </ul>
          <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <li class="nav-item dropdown">
                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle"/>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                  <div class="message-body">
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-user fs-6"></i>
                      <p class="mb-0 fs-3">My Profile</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-mail fs-6"></i>
                      <p class="mb-0 fs-3">My Account</p>
                    </a>
                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                      <i class="ti ti-list-check fs-6"></i>
                      <p class="mb-0 fs-3">My Task</p>
                    </a>
                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!--  Header End --> */}
      <div className="container-fluid">
      <div class="navbar-collapse justify-content-end px-0 mb-9" id="navbarNav">
            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
              <Link  to="/contactForm" class="btn btn-primary">Edit</Link>
            </ul>
          </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title fw-semibold mb-4 mt-9">CONTACT INFORMATION</h5>
                  <div className="text">
                    <p><strong>Phone Number:</strong> {contactData.phoneNumber}</p>
                    <p><strong>Email:</strong> {contactData.email}</p>
                    <p><strong>Work Hours:</strong> {contactData.workHours}</p>
                    <br />
                    <h3>Offices:</h3>
                    {contactData.offices.map((office, index) => (
                      <div key={index}>
                        <h4>Office {index + 1}</h4>
                        <p><strong>Address Line 1:</strong> {office.addressLine1}</p>
                        <p><strong>Address Line 2:</strong> {office.addressLine2}</p>
                        <p><strong>City:</strong> {office.city}</p>
                        <p><strong>State:</strong> {office.state}</p>
                        <p><strong>Post Code:</strong> {office.postCode}</p>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
  <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
  <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
  <script src="../assets/js/sidebarmenu.js"></script>
  <script src="../assets/js/app.min.js"></script>
  <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
</body>
    </Fragment>
    )
}

export default Contact;