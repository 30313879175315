/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Sidebar from "./sidebar";
let backendService;
let env = process.env.NODE_ENV;
if (env === 'development') {
    backendService = process.env.REACT_APP_Backend_Url_Dev;
  }
  else {
    backendService = process.env.REACT_APP_Backend_Url_Prod;
  }
function UpdateOverview() {
    const [overview, setOverview] = useState('');
    const location = useLocation();
    const overviewId = new URLSearchParams(location.search).get('id');
    const history = useNavigate();

    useEffect(() => {
        // Fetch the testimonial data using the testimonialId
        async function fetchService() {
            try {
                const response = await axios.get(`${backendService}/overview/${overviewId}`);
                const overviewData = response.data;
                setOverview(overviewData.Overview);
            } catch (error) {
                console.log(error);
            }
        }

        fetchService();
    }, [overviewId]);


    async function Submit(e) {
        e.preventDefault();

        // Create an object with the updated testimonial data
        const updatedOverview = {
            Overview: overview,
        };

        try {
            // Make a PUT request to update the testimonial
            await axios.put(`${backendService}/updateOverview/${overviewId}`, updatedOverview)
                .then(alert("Overview updated successufully")).then(history("/overview"))
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            <body>

                {/* <!--  Body Wrapper --> */}
                <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
                    data-sidebar-position="fixed" data-header-position="fixed">
                    {/* <!-- Sidebar Start --> */}
                    <Sidebar />
                    {/* <!--  Sidebar End -->
    <!--  Main wrapper --> */}
                    <div class="body-wrapper">
                        {/* <!--  Header Start --> */}
                        <header class="app-header">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <ul class="navbar-nav">
                                    <li class="nav-item d-block d-xl-none">
                                        <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                                            <i class="ti ti-menu-2"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link nav-icon-hover" href="javascript:void(0)">
                                            <i class="ti ti-bell-ringing"></i>
                                            <div class="notification bg-primary rounded-circle"></div>
                                        </a>
                                    </li>
                                </ul>
                                <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                                    <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle" />
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                                <div class="message-body">
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-user fs-6"></i>
                                                        <p class="mb-0 fs-3">My Profile</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-mail fs-6"></i>
                                                        <p class="mb-0 fs-3">My Account</p>
                                                    </a>
                                                    <a href="javascript:void(0)" class="d-flex align-items-center gap-2 dropdown-item">
                                                        <i class="ti ti-list-check fs-6"></i>
                                                        <p class="mb-0 fs-3">My Task</p>
                                                    </a>
                                                    <Link to="/" className="btn btn-outline-primary mx-3 mt-2 d-block">Logout</Link>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </header>
                        {/* <!--  Header End --> */}
                        <div class="container-fluid">
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-body">
                                        <Form onSubmit={Submit}>
                                            <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-between">
                                                <div>
                                                    <h1 className="px-0">Edit Overview</h1>
                                                </div>
                                                <div className="mb-5">
                                                    <Button variant="primary" type="submit" className="btn btn-primary">Save</Button>
                                                </div>
                                            </ul>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Control as="textarea" rows={5} required placeholder="Url" value={overview} onChange={(e) => { setOverview(e.target.value) }} />
                                            </Form.Group>
                                        </Form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <script src="../assets/libs/jquery/dist/jquery.min.js"></script>
                <script src="../assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
                <script src="../assets/js/sidebarmenu.js"></script>
                <script src="../assets/js/app.min.js"></script>
                <script src="../assets/libs/simplebar/dist/simplebar.js"></script>
            </body>
        </Fragment>
    )
}
export default UpdateOverview;